import { Box, Grid, HStack, Text } from '@chakra-ui/react'
import { ScoreWithConversationData } from 'components/ConversationDetails/Hooks/useScoresWithConversationData'
import React, { memo } from 'react'
import { Bar } from '../../../Scoring/components/StackedBar'
import { ScoreBreakdown, useBreakdown } from '../../../Scoring/useBreakdown'

type ScoreBarProps = {
  breakdown: ScoreBreakdown
  score: ScoreWithConversationData
}

const ScoreBar = memo(({ breakdown, score }: ScoreBarProps) => {
  const { hits, misses } = breakdown
  const templateColumns = hits
    .concat(misses)
    .map((x) => `${x.parameter.weight}fr`)
    .join(' ')

  return (
    <Box textAlign="right">
      <HStack justifyContent="space-between">
        <Text>{score.name}</Text>
        <Text>{score.conversationData.score}</Text>
      </HStack>
      <Grid templateColumns={templateColumns} h="3px">
        {hits.map((p, i) => (
          <Bar key={i} color={p.color} />
        ))}
        {misses.map((p) => (
          <Bar key={p.parameter.uid} color={p.color} />
        ))}
      </Grid>
    </Box>
  )
})

const ScoreProgress: React.FC<{
  score: ScoreWithConversationData
  conversationUid: string
}> = ({ score }) => {
  const breakdown = useBreakdown(score)

  if (!(score && breakdown)) return null

  return <ScoreBar breakdown={breakdown} score={score} />
}

export default ScoreProgress
