import { FormControl, FormLabel, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'
import { EmailChipsInput } from './EmailChipsInput'

const SubscriptionMailField: FC<{
  recipients: string[]
  setRecipients: (recipients: string[]) => void
}> = ({ recipients, setRecipients }) => {
  return (
    <FormControl>
      <FormLabel mb={0}>
        <Trans>Emails</Trans>
      </FormLabel>
      <Text color="gray.600" fontSize="xs" mb={1}>
        <Trans>
          The following emails will recieve the report (you can sign other
          people up)
        </Trans>
      </Text>
      <EmailChipsInput recipients={recipients} setRecipients={setRecipients} />
    </FormControl>
  )
}

export default SubscriptionMailField
