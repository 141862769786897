import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import {
  DaysPeriodType,
  MonthPeriodType,
  SubscriptionPayload,
  WeekPeriodType,
  YesterdayPeriodType,
} from '../../types'
import PeriodPreviousDays from './PeriodPreviousDays'

export type PeriodProps = {
  type: string
} & { [key: string]: number | string | (() => string) | undefined }

const periodItems = [
  [
    { type: 'Yesterday', sectionType: 'Yesterday' },
    { type: 'Days', sectionType: 'Days', value: 7 },
  ],
  [
    { type: 'Week', sectionType: 'WeekPrevious', week: 'Previous' },
    { type: 'Week', sectionType: 'WeekCurrent', week: 'Current' },
  ],
  [
    { type: 'Month', sectionType: 'MonthPrevious', month: 'Previous' },
    { type: 'Month', sectionType: 'MonthCurrent', month: 'Current' },
  ],
] as const

const days: DaysPeriodType = {
  type: 'Days',
  value: 7,
} as const

const yesterday: YesterdayPeriodType = {
  type: 'Yesterday',
} as const
const weekPrevious: WeekPeriodType = {
  type: 'Week',
  week: 'Previous',
} as const

const weekCurrent: WeekPeriodType = {
  type: 'Week',
  week: 'Current',
} as const

const monthPrevious: MonthPeriodType = {
  type: 'Month',
  month: 'Previous',
} as const

const monthCurrent: MonthPeriodType = {
  type: 'Month',
  month: 'Current',
} as const

const defaults = {
  Yesterday: yesterday,
  WeekPrevious: weekPrevious,
  WeekCurrent: weekCurrent,
  MonthPrevious: monthPrevious,
  MonthCurrent: monthCurrent,
  Days: days,
} as const

const translateLabels = (
  periodObj:
    | DaysPeriodType
    | MonthPeriodType
    | WeekPeriodType
    | YesterdayPeriodType,
) => {
  if (periodObj.type === 'Month') {
    switch (periodObj.month) {
      case 'Current':
        return t`Current month`
      case 'Previous':
        return t`Previous month`
    }
  }
  if (periodObj.type === 'Week') {
    switch (periodObj.week) {
      case 'Current':
        return t`Current week`
      case 'Previous':
        return t`Previous week`
    }
  }
  switch (periodObj.type) {
    case 'Yesterday':
      return t`Yesterday`
    case 'Days':
      return t`Previous day(s)`
  }
}

const Period: FC<{
  period: SubscriptionPayload['period']
  setPeriod: (state: SubscriptionPayload['period']) => void
}> = ({ period, setPeriod }) => {
  const [sectionType, setSectionType] = useState<string>('WeekPrevious')

  return (
    <>
      <FormLabel mb={1}>
        <Trans>Period</Trans>
      </FormLabel>
      <Flex gap={2}>
        <Menu>
          <MenuButton
            variant="outline"
            as={Button}
            rightIcon={<FiChevronDown />}
          >
            {translateLabels(period)}
          </MenuButton>
          <MenuList py={1}>
            {periodItems.map((list, index) => {
              return (
                <Box key={index}>
                  <Box>
                    {list.map((listItem, idx) => (
                      <MenuItem
                        fontWeight={
                          listItem.sectionType === sectionType
                            ? 'medium'
                            : 'initial'
                        }
                        key={`${listItem.type}${+idx}`}
                        onClick={() => {
                          setSectionType(listItem.sectionType)
                          setPeriod(defaults[listItem.sectionType])
                        }}
                      >
                        {translateLabels(listItem)}
                      </MenuItem>
                    ))}
                  </Box>
                  {index + 1 < periodItems.length && <Divider />}
                </Box>
              )
            })}
          </MenuList>
        </Menu>
        {period.type === 'Days' && (
          <Flex>
            <PeriodPreviousDays period={period} setPeriod={setPeriod} />
          </Flex>
        )}
      </Flex>
    </>
  )
}

export default Period
