import { Range } from '@capturi/api-filters'
import {
  CustomNumberPropSettings,
  CustomPropSettings,
  OrganizationMetadataSettings,
} from '@capturi/api-organization'
import { I18n, i18n as globalI18n } from '@lingui/core'
import { t } from '@lingui/macro'

export function getLocalizedCustomPropLabel(
  labels: Record<string, string> | undefined | null,
  locale: string,
): string | undefined {
  if (labels == null) return undefined
  return labels[locale] ?? Object.values(labels)[0]
}

export function isRangeMatch(
  a: Range | null | undefined,
  b: Range | null | undefined,
): boolean {
  if (a == null || b == null) return false
  return a.min === b.min && a.max === b.max
}

export const formatRange = (
  min: number | undefined | null,
  max: number | undefined | null,
  i18n: I18n = globalI18n,
): string => {
  if (min != null && min === max) {
    return String(min)
  }
  if (min != null && max != null) {
    return t(i18n)`Between ${min} and ${max}`
  }
  if (min != null) {
    return t(i18n)`Minimum ${min}`
  }
  if (max != null) {
    return t(i18n)`Maximum ${max}`
  }
  return ''
}

export const getLocalizedCustomPropValue = (
  filterKey: keyof OrganizationMetadataSettings,
  locale: string,
  organizationMetadata?: OrganizationMetadataSettings,
  value?: unknown,
  i18n: I18n = globalI18n,
) => {
  let strValue: string | string[] | undefined = undefined
  if (filterKey.startsWith('customNumberProp')) {
    const settings = organizationMetadata?.[
      filterKey
    ] as CustomNumberPropSettings
    const rangeValue = value as Range | undefined
    if (settings?.filterType === 'Range') {
      strValue = formatRange(rangeValue?.min, rangeValue?.max, i18n)
    }
    if (settings?.filterType === 'Dropdown') {
      const option = settings.filters?.find((x) => isRangeMatch(x, rangeValue))
      strValue = getLocalizedCustomPropLabel(option?.label, locale) ?? '?'
    }
  } else {
    const settings = organizationMetadata?.[filterKey] as CustomPropSettings
    if (settings?.filterType === 'Text') {
      strValue = Array.isArray(value) ? value[0] : value
    }
    if (settings?.filterType === 'Dropdown') {
      const strArrayValue = Array.isArray(value) ? value : [value]
      strValue = strArrayValue
        .map((value) => {
          const option = settings.values?.find((x) => x.value === value)
          return getLocalizedCustomPropLabel(option?.label, locale)
        })
        .filter((x): x is string => x !== undefined)
    }
  }
  return strValue ?? '?'
}
