import {
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useState } from 'react'
import Recurrence from './components/Recurrence/Recurrence'
import SubscriptionMailField from './components/SubscriptionEmails/SubscriptionMailField'
import SubscriptionFilter from './components/SubscriptionFilter'
import SubscriptionFooter from './components/SubscriptionFooter'
import SubscriptionName from './components/SubscriptionName'
import { useCreateSubscription } from './hooks/useSubscriptions'
import { SubscriptionPayload } from './types'

type Props = { isOpen: boolean; onClose: () => void }

const SubscriptionModal: React.FC<Props> = ({ onClose, isOpen }) => {
  const [subscriptionState, setSubscriptionState] =
    useState<SubscriptionPayload>({
      name: '',
      filter: {},
      period: { type: 'Week', week: 'Previous' },
      recipients: [],
      schedule: { type: 'Weekly', days: ['Monday'] },
    })

  const { mutate: createSubsciption } = useCreateSubscription()
  const toast = useToast()

  const onCreateSubscription = (payload: SubscriptionPayload) => {
    createSubsciption(payload, {
      onSuccess: () => {
        toast({
          status: 'success',
          title: t`Successfully created subscription`,
        })
        onClose()
      },
      onError: (error) => {
        toast({
          status: 'error',
          title: t`Failed to create subscription. Please try again`,
          description: error.message,
        })
      },
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>New subscription</Trans>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          borderBottom="1px solid"
          borderTop="1px solid"
          borderColor="gray.200"
        >
          <Grid
            minH="512px"
            templateColumns={{
              base: '1fr 1fr',
            }}
            gap={6}
          >
            <Stack
              borderRight={{
                base: 'none',
                md: '1px solid var(--chakra-colors-gray-200)',
              }}
              pr={6}
            >
              <Flex>
                <SubscriptionName
                  name={subscriptionState.name}
                  setName={(name) =>
                    setSubscriptionState((s) => ({ ...s, name }))
                  }
                />
              </Flex>
              <Flex>
                <SubscriptionFilter
                  filter={subscriptionState.filter}
                  setFilter={(filter) =>
                    setSubscriptionState((s) => ({ ...s, filter }))
                  }
                />
              </Flex>
              <Flex w="100%" flexDir="column">
                <Recurrence
                  schedule={subscriptionState.schedule}
                  setSchedule={(schedule) =>
                    setSubscriptionState((s) => {
                      if (
                        schedule.type === 'Monthly' &&
                        schedule.dayOfMonth === 'Last'
                      ) {
                        return {
                          ...s,
                          schedule,
                          period: { type: 'Month', month: 'Current' },
                        }
                      }
                      if (
                        schedule.type === 'Monthly' &&
                        schedule.dayOfMonth === 'First'
                      ) {
                        return {
                          ...s,
                          schedule,
                          period: { type: 'Month', month: 'Previous' },
                        }
                      }
                      return { ...s, schedule }
                    })
                  }
                  period={subscriptionState.period}
                  setPeriod={(period) =>
                    setSubscriptionState((s) => ({ ...s, period }))
                  }
                />
              </Flex>
            </Stack>
            <Stack>
              <SubscriptionMailField
                recipients={subscriptionState.recipients}
                setRecipients={(recipients) =>
                  setSubscriptionState((s) => ({
                    ...s,
                    recipients,
                  }))
                }
              />
            </Stack>
          </Grid>
        </ModalBody>
        <SubscriptionFooter
          isDisabled={
            !subscriptionState.name || !subscriptionState.recipients.length
          }
          onCreateSubscription={() => onCreateSubscription(subscriptionState)}
          onClose={onClose}
        />
      </ModalContent>
    </Modal>
  )
}

export default SubscriptionModal
