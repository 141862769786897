import {
  ConversationResponseModel,
  useConversation,
} from '@capturi/api-conversations'
import { useScores } from '@capturi/api-scoring'
import { Box, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { useScoresWithConversationData } from 'components/ConversationDetails/Hooks/useScoresWithConversationData'
import React, { useMemo } from 'react'

import ScoreProgress from './ScoreProgress'

const ScoringOverview: React.FC<{
  conversationScores: ConversationResponseModel['scores']
  conversationUid: string
}> = ({ conversationScores, conversationUid }) => {
  const [shouldDirtyPoll, setShouldDirtyPoll] = React.useState(false)
  const { data: scores } = useScores(shouldDirtyPoll ? 7000 : 0)
  const { refetch: refetchConversation } = useConversation(
    conversationUid,
    shouldDirtyPoll ? 7000 : 0,
  )

  React.useEffect(() => {
    if (!scores) return

    const isProcessing = scores.some((x) => x.isProcessing === true)
    if (isProcessing) {
      if (!shouldDirtyPoll) {
        setShouldDirtyPoll(true)
      }
    } else {
      if (shouldDirtyPoll) {
        setShouldDirtyPoll(false)
        refetchConversation()
      }
    }
  }, [scores, shouldDirtyPoll, refetchConversation])
  const memoizedConversationScores = useMemo(
    () => Object.values(conversationScores),
    [conversationScores],
  )
  const scoresWithConversationData = useScoresWithConversationData(
    scores,
    memoizedConversationScores,
  )
  return (
    <Box overflowY="auto" px={0}>
      <VStack align="stretch">
        {scoresWithConversationData?.length ? (
          scoresWithConversationData.map((item) => {
            return (
              <ScoreProgress
                key={item.uid}
                score={item}
                conversationUid={conversationUid}
              />
            )
          })
        ) : (
          <Text fontSize="sm" fontWeight="medium">
            <Trans>No results</Trans>
          </Text>
        )}
      </VStack>
    </Box>
  )
}

export default ScoringOverview
