import { Button } from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { ModalFooter, Tooltip } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

type Props = {
  isDisabled: boolean
  onCreateSubscription: () => void
} & BaseModalComponentProps

const SubscriptionFooter: React.FC<Props> = ({
  onClose,
  isDisabled,
  onCreateSubscription,
}) => {
  return (
    <ModalFooter>
      <Button mr={3} onClick={onClose}>
        <Trans>Cancel</Trans>
      </Button>
      <Tooltip
        label={t`Name and at least one recipient is required.`}
        hasArrow
        placement="top"
        isDisabled={!isDisabled}
      >
        <Button
          isDisabled={isDisabled}
          primary
          onClick={() => {
            onCreateSubscription()
          }}
        >
          <Trans>Save</Trans>
        </Button>
      </Tooltip>
    </ModalFooter>
  )
}

export default SubscriptionFooter
