import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { FC } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { MonthlyScheduleType } from '../../types'

const recurrenceItems = ['Last', 'First'] as const

const translateLabels = (dayOfMonth: 'Last' | 'First'): string => {
  switch (dayOfMonth) {
    case 'Last':
      return t`Last`
    case 'First':
      return t`First`
  }
}

const RecurrenceMonthly: FC<{
  schedule: MonthlyScheduleType
  setSchedule: (schedule: MonthlyScheduleType) => void
}> = ({ schedule, setSchedule }) => {
  return (
    <Box ml={2}>
      <Menu>
        <MenuButton variant="outline" as={Button} rightIcon={<FiChevronDown />}>
          {translateLabels(schedule.dayOfMonth)}
        </MenuButton>
        <Text display="inline-block" ml={2} color="gray.600">
          <Trans>day of the month</Trans>
        </Text>
        <MenuList>
          {recurrenceItems.map((value) => (
            <MenuItem
              fontWeight={value === schedule.dayOfMonth ? 'medium' : 'initial'}
              key={value}
              onClick={() =>
                setSchedule({
                  type: 'Monthly',
                  dayOfMonth: value,
                })
              }
            >
              {translateLabels(value)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  )
}

export default RecurrenceMonthly
