import { PhoneFilterValues } from '@capturi/api-filters'
import { useCurrentUser } from '@capturi/core'
import {
  FilterMenuPlacementProvider,
  FilterPeriodProvider,
  PhoneFilter,
  useFilterDefinitions,
  useFilterPeriodContext,
} from '@capturi/filters'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'

const SubscriptionFilter: FC<{
  filter: Partial<PhoneFilterValues>
  setFilter: (state: Partial<PhoneFilterValues>) => void
}> = ({ filter, setFilter }) => {
  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)
  const { periodDef } = useFilterPeriodContext()
  return (
    <FilterPeriodProvider defaultPeriod={periodDef}>
      <FilterMenuPlacementProvider value="top-start">
        <FormControl>
          <FormLabel>
            <Trans>Filter</Trans>
          </FormLabel>
          <PhoneFilter
            filterDefinitions={filterDefinitions}
            state={{
              channel: 'phone',
              values: {
                ...filter,
              },
            }}
            onStateChange={(filter) => {
              setFilter(filter.values)
            }}
          />
        </FormControl>
      </FilterMenuPlacementProvider>
    </FilterPeriodProvider>
  )
}

export default SubscriptionFilter
