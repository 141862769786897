import { Card, CardBody, CardHeader } from '@chakra-ui/react'
import React, { PropsWithChildren, useCallback } from 'react'

const OverviewCard: React.FC<
  PropsWithChildren & {
    title: string
    value: string
    onClick: (value: string) => void
  }
> = ({ title, children, value, onClick }) => {
  const handleClick = useCallback(() => {
    onClick(value)
  }, [onClick, value])

  return (
    <Card
      flex="50%"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="4px"
      mb={4}
      boxShadow="none"
    >
      <CardHeader
        onClick={handleClick}
        cursor="pointer"
        pt={4}
        pl={4}
        pb={children ? 2 : 4}
        fontWeight="medium"
        fontSize="sm"
        color="gray.600"
      >
        {`${title} >`}
      </CardHeader>
      {children && (
        <CardBody overflowY="visible" pt={0} p={4}>
          {children}
        </CardBody>
      )}
    </Card>
  )
}

export default OverviewCard
