import analytics from '@capturi/analytics'
import { SavedPhoneFilter } from '@capturi/api-filters'
import { ErrorBoundary } from '@capturi/react-utils'
import { Menu, MenuButton, MenuItem, MenuList } from '@capturi/ui-components'
import {
  Box,
  MenuDivider,
  Popover,
  PopoverContent,
  PopoverProps,
  useControllableState,
  useDisclosure,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans, t } from '@lingui/macro'
import {
  type FC,
  Fragment,
  type MouseEvent,
  type MutableRefObject,
  type PropsWithChildren,
  type ReactElement,
  Suspense,
  createContext,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'
import { IoMdPie } from 'react-icons/io'
import { useLatest } from 'react-use'

import { useFilterMenuPlacementContext } from '../../../../hooks/useMenuPlacementContext'
import { SavedPhoneFilterPopoverContent } from '../SavedPhoneFilterButton'
import { AddFilterCriteriaButton } from './components/AddFilterCriteriaButton'
import { FilterDefinition, FilterDefinitions } from './types'
import { useAvailableFilters } from './useAvailableFilters'
import { useFilterComponents } from './useFilterComponents'
import { FilterCriteria, useFilterCriterias } from './useFilterCriterias'

// biome-ignore lint/suspicious/noExplicitAny: legacy
export type SegmentBuilderProps<TValues extends Record<string, any>> = {
  /**
   * The state to be used in controlled mode
   */
  state?: Partial<TValues>
  /**
   * The initial state to be used, in uncontrolled mode
   */
  defaultState?: Partial<TValues>
  /**
   * Possible filters and their definitions
   */
  filterDefinitions: FilterDefinitions
  /**
   * The callback fired when the state changes
   */
  onStateChange?: (values: Partial<TValues>) => void
  /**
   * Callback when a filter criteria is added
   */
  onAddFilterCriteria?: (
    filterName: string,
    definition?: FilterDefinition,
  ) => void
  /**
   * Callback when a filter criteria is removed
   */
  onRemoveFilterCriteria?: (
    filterName: string,
    definition?: FilterDefinition,
  ) => void
  /**
   * Callback when a filter criteria is added
   */
  onFilterCriteriaValueReset?: (
    filterName: string,
    definition?: FilterDefinition,
  ) => void
  /**
   * Callback when the value of a filter criteria (multi-select) is reset
   */
  onFilterCriteriaValueChange?: (
    filterName: string,
    definition: FilterDefinition | undefined,
    // biome-ignore lint/suspicious/noExplicitAny: legacy
    newValue: any,
  ) => void
  /**
   * Is the builder in read-only mode, i.e. can any changes be made
   */
  isReadOnly?: boolean

  savedFilter: SavedPhoneFilter | undefined
  onChangeSavedFilter: (savedFilter: SavedPhoneFilter) => void
}

export const ReadOnlyContext = createContext(false)

export const SegmentationFieldContext = createContext<
  number | string | undefined
>('25rem')

type SegmentBuilderHandle<TValues> = {
  initFilterValues: (values: Partial<TValues>) => void
}

type Ref<T> = ((instance: T | null) => void) | MutableRefObject<T | null> | null

// biome-ignore lint/suspicious/noExplicitAny: legacy
function PhoneSegmentBuilderWithRef<TValues extends Record<string, any>>({
  state: stateProp,
  defaultState,
  filterDefinitions,
  onStateChange,
  onAddFilterCriteria: onAddFilterCriteriaProp,
  onRemoveFilterCriteria: onRemoveFilterCriteriaProp,
  onFilterCriteriaValueReset: onFilterCriteriaValueResetProp,
  onFilterCriteriaValueChange: onFilterCriteriaValueChangeProp,
  isReadOnly = false,
  savedFilter,
  onChangeSavedFilter,
  builderRef,
  children,
}: PropsWithChildren<
  SegmentBuilderProps<TValues> & {
    builderRef: Ref<SegmentBuilderHandle<TValues>>
  }
>): ReactElement {
  const onAddFilterCriteriaCallbackRef = useLatest(onAddFilterCriteriaProp)
  const onRemoveFilterCriteriaCallbackRef = useLatest(
    onRemoveFilterCriteriaProp,
  )
  const onFilterCriteriaValueResetCallbackRef = useLatest(
    onFilterCriteriaValueResetProp,
  )
  const onFilterCriteriaValueChangeCallbackRef = useLatest(
    onFilterCriteriaValueChangeProp,
  )

  const {
    onOpen: onOpenPopover,
    isOpen: isOpenPopover,
    onClose: onClosePopover,
  } = useDisclosure({ defaultIsOpen: false })

  const {
    onOpen: onOpenMenu,
    isOpen: isOpenMenu,
    onClose: onCloseMenu,
  } = useDisclosure()

  const closeAllMenus = useCallback(() => {
    onClosePopover()
    onCloseMenu()
  }, [onClosePopover, onCloseMenu])

  const handleChangeSavedFilter = useCallback(
    (savedFilter: SavedPhoneFilter) => {
      onChangeSavedFilter(savedFilter)
      closeAllMenus()
    },
    [onChangeSavedFilter, closeAllMenus],
  )

  const openSavedSegmentPopover = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      onOpenPopover()
    },
    [onOpenPopover],
  )

  // Keeping track of the currently focused filter, i.e. which filter dialog is open
  const [focusedFilterEntry, setFilterEntryFocus] = useState<FilterCriteria>()

  // Filter state
  const [state, setState] = useControllableState<Partial<TValues>>({
    value: stateProp,
    defaultValue: defaultState ?? {},
    onChange: onStateChange,
  })

  // Manage filter criterias in the UI.
  const { filters, addFilter, removeFilter, initFilters } = useFilterCriterias(
    state,
    filterDefinitions,
  )

  // Expose a handle for parent components to reset initialise the filter criterias
  useImperativeHandle(builderRef, () => ({
    initFilterValues: (values) => {
      initFilters(values)
    },
  }))

  const onAddFilterCriteria = (key: string): void => {
    if (isReadOnly) return
    const entry = addFilter(key)
    setTimeout(() => setFilterEntryFocus(entry))
    onAddFilterCriteriaCallbackRef.current?.(key, filterDefinitions.get(key))
  }

  const resetFilterValue = useCallback(
    (filter: FilterCriteria) => {
      if (isReadOnly) return
      const filterDefinition = filterDefinitions.get(filter.stateKey)
      // Reset filter state
      setState((state) => {
        const currentValue = state[filter.stateKey as keyof TValues]
        if (filterDefinition?.allowMultiple && Array.isArray(currentValue)) {
          // Shallow clone list value
          const newListValue = [...currentValue]
          // Remove entry in list
          newListValue.splice(filter.index, 1)
          return {
            ...state,
            [filter.stateKey]: newListValue,
          }
        }
        const newState = { ...state }
        delete newState[filter.stateKey]
        return newState
      })
    },
    [filterDefinitions, setState, isReadOnly],
  )

  // Reset a filter criteria value
  const onFilterValueReset = useCallback(
    (filter: FilterCriteria) => {
      if (isReadOnly) return
      resetFilterValue(filter)
      onFilterCriteriaValueResetCallbackRef.current?.(
        filter.stateKey,
        filterDefinitions.get(filter.stateKey),
      )
    },
    [
      resetFilterValue,
      filterDefinitions,
      onFilterCriteriaValueResetCallbackRef,
      isReadOnly,
    ],
  )

  const onRemoveFilterCriteria = useCallback(
    (filter: FilterCriteria): void => {
      if (isReadOnly) return
      // Reset state value
      resetFilterValue(filter)
      // Remove filter entry
      removeFilter(filter.id)
      // Reset variable controlling which popover is open if id matches currently open popup
      if (filter.id === focusedFilterEntry?.id) {
        setFilterEntryFocus(undefined)
      }
      onRemoveFilterCriteriaCallbackRef.current?.(
        filter.stateKey,
        filterDefinitions.get(filter.stateKey),
      )
    },
    [
      removeFilter,
      focusedFilterEntry,
      resetFilterValue,
      onRemoveFilterCriteriaCallbackRef,
      filterDefinitions,
      isReadOnly,
    ],
  )

  // Set a filter criteria value
  const onFilterValueChange = useCallback(
    // biome-ignore lint/suspicious/noExplicitAny: legacy
    (filter: FilterCriteria, newValue: any) => {
      if (isReadOnly) return
      const filterDefinition = filterDefinitions.get(filter.stateKey)
      setState((state) => {
        const currentValue = state[filter.stateKey as keyof TValues]
        let value = newValue
        if (filterDefinition?.allowMultiple) {
          // Shallow clone list value
          const newListValue = Array.isArray(currentValue)
            ? [...currentValue]
            : []
          // Update entry in list
          newListValue[filter.index] = newValue
          value = newListValue
        }
        return {
          ...state,
          [filter.stateKey]: value,
        }
      })
      onFilterCriteriaValueChangeCallbackRef.current?.(
        filter.stateKey,
        filterDefinition,
        newValue,
      )
    },
    [
      filterDefinitions,
      setState,
      onFilterCriteriaValueChangeCallbackRef,
      isReadOnly,
    ],
  )

  // Derive which filters are available in the UI
  const availableFilters = useAvailableFilters(
    filters,
    state,
    filterDefinitions,
  )

  // Generate the list of filter criteria components to be rendered
  const filterComponents = useFilterComponents(
    state,
    filters,
    focusedFilterEntry,
    setFilterEntryFocus,
    onRemoveFilterCriteria,
    onFilterValueChange,
    onFilterValueReset,
    filterDefinitions,
  )

  const placement = useFilterMenuPlacementContext()

  return (
    <>
      {filterComponents.map(({ filter, element, isOpen }) => (
        //To force the component to rerender, when it opens, we need to update the key
        // a better solution would be to not export the final components but instead do something like
        // filter.map(f=> <FilterComponent {...f} />)
        <Box key={`${filter.id}_${isOpen}`}>{element}</Box>
      ))}
      {!isReadOnly && availableFilters.length > 0 && (
        <Box>
          <Menu
            placement={placement}
            onOpen={onOpenMenu}
            isOpen={isOpenMenu}
            onClose={closeAllMenus}
          >
            <AddFilterCriteriaButton
              component={MenuButton}
              showText={filters.length === 0}
            />
            <MenuList
              maxH="min(calc( 100vh - 264px ),700px)"
              scrollBehavior="smooth"
              overflowY="auto"
              css={css`
                &::-webkit-scrollbar {
                  display: none;
                }
              `}
            >
              <MenuItem
                closeOnSelect={false}
                icon={<IoMdPie />}
                onClick={openSavedSegmentPopover}
              >
                <Trans>Saved segments</Trans>
              </MenuItem>
              <SavedSegmentPopover
                savedFilter={savedFilter}
                onChangeSavedFilter={handleChangeSavedFilter}
                onClose={closeAllMenus}
                isOpen={isOpenPopover}
              />
              <MenuDivider />
              {availableFilters.map((filterGroup, i, arr) => {
                return (
                  <Fragment key={i}>
                    {filterGroup.map((key) => {
                      const definition = filterDefinitions.get(key)
                      if (!definition) return null
                      return (
                        <MenuItem
                          key={key}
                          onClick={() => onAddFilterCriteria(key)}
                          command={definition.hasNewBadge ? t`New` : undefined}
                          css={css`
                            span{
                            display: flex;
                            align-items: center;
                            }
                            .chakra-menu__command{
                              background: #D5EAF6;
                              padding: 0 4px 0 4px;
                              font-size: 11px;
                              text-transform: uppercase;
                              font-weight: 500;
                              color:#133C53;
                              border-radius:2px;
                              overflow: hidden;
                              opacity: 1;
                              line-height: 1.2;
                            }
              `}
                        >
                          <Box as={definition.icon} mr={2} />
                          {definition.name}
                        </MenuItem>
                      )
                    })}
                    {i < arr.length - 1 && <MenuDivider />}
                  </Fragment>
                )
              })}
            </MenuList>
          </Menu>
        </Box>
      )}
      {children}
    </>
  )
}

type SavedSegmentPopoverProps = {
  savedFilter: SavedPhoneFilter | undefined
  onChangeSavedFilter: (savedFilter: SavedPhoneFilter) => void
} & PopoverProps

const SavedSegmentPopover: FC<SavedSegmentPopoverProps> = ({
  savedFilter,
  onChangeSavedFilter,
  ...restProps
}) => {
  return (
    <Popover
      onClose={restProps.onClose}
      isLazy
      closeOnBlur={false}
      {...restProps}
    >
      {({ onClose }) => (
        <PopoverContent>
          <ErrorBoundary>
            <Suspense fallback={<Box h={8} />}>
              <SavedPhoneFilterPopoverContent
                value={savedFilter}
                onChange={(filter) => {
                  onChangeSavedFilter(filter)
                  analytics.event('segment_trackers_noView')
                  onClose()
                }}
              />
            </Suspense>
          </ErrorBoundary>
        </PopoverContent>
      )}
    </Popover>
  )
}

export const PhoneSegmentBuilder = forwardRef(
  function SegmentBuilderWrapper(props, ref) {
    return (
      <ReadOnlyContext.Provider value={props.isReadOnly ?? false}>
        <PhoneSegmentBuilderWithRef {...props} builderRef={ref} />
      </ReadOnlyContext.Provider>
    )
  },
  // biome-ignore lint/suspicious/noExplicitAny: legacy
) as <TValues extends Record<string, any>>(
  props: SegmentBuilderProps<TValues> & {
    ref?: Ref<SegmentBuilderHandle<TValues>>
  },
) => ReturnType<typeof PhoneSegmentBuilderWithRef>
