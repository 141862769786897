import {
  ConversationResponseModel,
  useCallHistory,
} from '@capturi/api-conversations'
import { useFeatureFlags } from '@capturi/feature-flags'
import { Flex, Text } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { Trans } from '@lingui/macro'
import { Hit } from 'components/ConversationDetails/Audio/types'
import { useComments } from 'components/ConversationDetails/Hooks/useComments'
import { useTrackerHits } from 'components/ConversationDetails/Hooks/useTrackerHits'
import OverviewTrackerList from 'components/ConversationDetails/Trackers/TableOfContents/OverviewTrackerList'
import { useTableOfContents } from 'components/ConversationDetails/Trackers/TableOfContents/useTableOfContents'
import OverviewCard from 'components/ConversationDetails/components/Overview/OverviewCard'
import { ConversationPageView } from 'pages/Conversations/routes'
import React, { useCallback } from 'react'

import { BaseTab } from '../useTabsState'
import AiOverview from './AiOverview/AiOverview'
import CallHistoryOverview from './CallHistoryOverview/CallHistoryOverview'
import ScoringOverview from './ScoringOverview/ScoringOverview'
import SentimentOverview from './SentimentOverview/SentimentOverview'

type Props = {
  conversation: ConversationResponseModel
  tabs: BaseTab<ConversationPageView>[]
  selectedTrackerUid: string | null
  setHits: (hits: Hit[]) => void
  onNavigateToTracker: (currentTrackerUid: string | null) => void
  onTabsChange: (index: number) => void
}

const Overview: React.FC<Props> = ({
  conversation,
  tabs,
  selectedTrackerUid,
  setHits,
  onNavigateToTracker,
  onTabsChange,
}) => {
  const { trackerHitMap, trackerHits, contents } = useTrackerHits(conversation)
  const { aiFeatures } = useFeatureFlags()
  const { data: comments } = useComments(conversation.uid)
  const { data: callHistory } = useCallHistory(conversation.customer)
  const { combined } = useTableOfContents(trackerHits, trackerHitMap)

  const totalHits = React.useMemo(() => {
    return Array.from(trackerHitMap.values()).reduce((acc, hit) => {
      return acc + hit.count
    }, 0)
  }, [trackerHitMap])

  const handleOverviewCardClick = useCallback(
    (value: string) => {
      onTabsChange(tabs.findIndex((tab) => tab.value === value))
    },
    [onTabsChange, tabs],
  )

  return (
    <Flex
      gap={conversation.aiInsights ? 4 : 2}
      alignItems="start"
      flexDir={conversation.aiInsights ? 'row' : 'column'}
    >
      {aiFeatures && (
        <AiOverview
          conversationUid={conversation.uid}
          aiInsights={conversation.aiInsights}
        />
      )}
      <Flex
        flexDir={conversation.aiInsights ? 'column' : 'row'}
        flexWrap={conversation.aiInsights ? 'initial' : 'wrap'}
        w={conversation.aiInsights ? 'initial' : '100%'}
        flex="1 0 40%"
      >
        <Text fontWeight="medium" fontSize="sm" mb="6px">
          <Trans>Overview</Trans>
        </Text>
        <Flex
          gap={conversation.aiInsights ? 0 : 2}
          flexDir={conversation.aiInsights ? 'column' : 'row'}
          w={conversation.aiInsights ? 'initial' : '100%'}
        >
          <OverviewCard
            onClick={handleOverviewCardClick}
            title={t`Trackers${totalHits ? ` (${totalHits})` : ''}`}
            value={'trackers'}
          >
            <OverviewTrackerList
              combined={combined}
              hitMap={trackerHitMap}
              selectedTrackerUid={selectedTrackerUid}
              contents={contents}
              setHits={setHits}
              onNavigateToTracker={onNavigateToTracker}
              conversation={conversation}
            />
          </OverviewCard>
          <OverviewCard
            onClick={handleOverviewCardClick}
            title={t`Sentiment`}
            value={'sentiment'}
          >
            <SentimentOverview
              sentiment={conversation.sentiment}
              userUid={conversation.userUid}
            />
          </OverviewCard>
        </Flex>
        <Flex
          gap={conversation.aiInsights ? 0 : 2}
          flexDir={conversation.aiInsights ? 'column' : 'row'}
          w={conversation.aiInsights ? 'initial' : '100%'}
        >
          <OverviewCard
            onClick={handleOverviewCardClick}
            title={t`Scoring`}
            value={'scoring'}
          >
            <ScoringOverview
              conversationScores={conversation.scores}
              conversationUid={conversation.uid}
            />
          </OverviewCard>
          {conversation?.details?.nextConversationDistance ||
          conversation?.details?.previousConversationDistance ? (
            <OverviewCard
              onClick={handleOverviewCardClick}
              title={t`Call history${
                callHistory?.conversations.length
                  ? ` (${callHistory?.conversations.length})`
                  : ''
              }`}
              value={'history'}
            >
              <CallHistoryOverview
                previousConversationDistance={
                  conversation?.details?.previousConversationDistance
                }
                nextConversationDistance={
                  conversation?.details?.nextConversationDistance
                }
              />
            </OverviewCard>
          ) : null}

          <OverviewCard
            onClick={handleOverviewCardClick}
            title={t`Comments${
              comments?.length ? ` (${comments.length})` : ''
            }`}
            value={'coaching'}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Overview
