import analytics from '@capturi/analytics'
import {
  Box,
  Flex,
  List,
  ListItem,
  Progress,
  Text,
  VStack,
} from '@chakra-ui/react'
import { FC, memo } from 'react'

type KeyTopicsListItemProps = {
  id: string
  amount: number
  repeatCallsAmount?: number
  repeatCallsShare?: number
  label: string
  isSelected: boolean
  progressColorScheme: string
  onClick: (id: string) => void
}

const KeyTopicsListItem: FC<KeyTopicsListItemProps> = memo(
  ({
    id,
    amount,
    repeatCallsAmount,
    repeatCallsShare,
    label,
    isSelected,
    progressColorScheme,
    onClick,
  }) => {
    const share = (repeatCallsShare ?? 0) * 100
    return (
      <ListItem
        borderColor={isSelected ? 'curiousBlue.300' : 'transparent'}
        borderWidth="1px"
        borderRadius="lg"
        onClick={() => onClick(id)}
        pl={3}
        pr={2}
        pb={2}
        pt={2}
        overflow="hidden"
        position="relative"
        boxShadow={
          isSelected
            ? '1px 1px 2px 0px rgba(255, 255, 255, 0.30) inset, -1px -1px 2px 0px rgba(223, 223, 223, 0.50) inset, -5px 5px 10px 0px rgba(223, 223, 223, 0.20), 5px -5px 10px 0px rgba(223, 223, 223, 0.20), -5px -5px 10px 0px rgba(255, 255, 255, 0.90), 5px 5px 13px 0px rgba(223, 223, 223, 0.90)'
            : ''
        }
        background={
          isSelected ? 'linear-gradient(135deg, #FFF 0%, #EAEAEA 100%)' : ''
        }
        _hover={{
          bg: isSelected ? '' : 'gray.100',
        }}
        cursor="pointer"
        transition="all 250ms ease-out"
      >
        <Box
          position="absolute"
          left={0}
          top={0}
          bottom={0}
          backgroundColor={isSelected ? 'curiousBlue.500' : 'transparent'}
          w="4px"
        />
        <VStack alignItems="stretch" gap={0} w="100%">
          <Flex alignItems="center" justifyContent="start" gap={1}>
            <Text
              fontSize="xs"
              fontWeight="medium"
              lineHeight="5"
              noOfLines={1}
            >
              {label}
            </Text>
            {repeatCallsAmount !== undefined && (
              <Text fontSize="xs" color="gray.600">
                ({repeatCallsAmount}/{amount})
              </Text>
            )}
          </Flex>

          <Flex alignItems="center" gap={2} justifyContent="space-between">
            <Progress
              borderRadius="3px"
              flexGrow={1}
              value={share}
              size="sm"
              colorScheme={progressColorScheme}
            />
            <Text fontSize="sm" noOfLines={1}>
              {share?.toFixed()}%
            </Text>
          </Flex>
        </VStack>
      </ListItem>
    )
  },
)

type KeyTopicsListItemType = {
  id: string
  label: string
  amount: number
  repeatCallsAmount: number
  repeatCallsShare: number
}

type KeyTopicsListProps = {
  topItems: KeyTopicsListItemType[]
  bottomItems: KeyTopicsListItemType[]
  selectedKeyTopicId: string | undefined
  setSelectedKeyTopicId: (id: string) => void
}

export const KeyTopicsList: FC<KeyTopicsListProps> = memo(
  ({ topItems, bottomItems, selectedKeyTopicId, setSelectedKeyTopicId }) => {
    return (
      <List w="16.5rem">
        {topItems.map(
          ({ id, label, amount, repeatCallsAmount, repeatCallsShare }) => (
            <KeyTopicsListItem
              key={id}
              id={id}
              label={label}
              amount={amount}
              repeatCallsAmount={repeatCallsAmount}
              repeatCallsShare={repeatCallsShare}
              isSelected={selectedKeyTopicId === id}
              progressColorScheme="segments.senary"
              onClick={() => {
                analytics.event('repeat_calls_topic_selected')
                setSelectedKeyTopicId(id)
              }}
            />
          ),
        )}
        {bottomItems.length > 0 && (
          <>
            <Box w="100%" overflow="hidden" my={4}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="301"
                height="9"
                viewBox="0 0 301 9"
                fill="none"
              >
                <path
                  d="M1 4.5C6.7871 -0.166667 4.85806 -0.166667 10.6452 4.5C16.4323 9.16667 14.5032 9.16667 20.2903 4.5C26.0774 -0.166667 24.1484 -0.166667 29.9355 4.5C35.7226 9.16667 33.7935 9.16667 39.5806 4.5C45.3677 -0.166667 43.4387 -0.166667 49.2258 4.5C55.0129 9.16667 53.0839 9.16667 58.871 4.5C64.6581 -0.166667 62.729 -0.166667 68.5161 4.5C74.3032 9.16667 72.3742 9.16667 78.1613 4.5C83.9484 -0.166667 82.0194 -0.166667 87.8064 4.5C93.5935 9.16667 91.6645 9.16667 97.4516 4.5C103.239 -0.166667 101.31 -0.166667 107.097 4.5C112.884 9.16667 110.955 9.16667 116.742 4.5C122.529 -0.166667 120.6 -0.166667 126.387 4.5C132.174 9.16667 130.245 9.16667 136.032 4.5C141.819 -0.166667 139.89 -0.166667 145.677 4.5C151.465 9.16667 149.535 9.16667 155.323 4.5C161.11 -0.166667 159.181 -0.166667 164.968 4.5C170.755 9.16667 168.826 9.16667 174.613 4.5C180.4 -0.166667 178.471 -0.166667 184.258 4.5C190.045 9.16667 188.116 9.16667 193.903 4.5C199.69 -0.166667 197.761 -0.166667 203.548 4.5C209.335 9.16667 207.406 9.16667 213.194 4.5C218.981 -0.166667 217.052 -0.166667 222.839 4.5C228.626 9.16667 226.697 9.16667 232.484 4.5C238.271 -0.166667 236.342 -0.166667 242.129 4.5C247.916 9.16667 245.987 9.16667 251.774 4.5C257.561 -0.166667 255.632 -0.166667 261.419 4.5C267.206 9.16667 265.277 9.16667 271.065 4.5C276.852 -0.166667 274.923 -0.166667 280.71 4.5C286.497 9.16667 284.568 9.16667 290.355 4.5C296.142 -0.166667 294.213 -0.166667 300 4.5"
                  stroke="#E0E0E0"
                />
              </svg>
            </Box>
            {bottomItems.map(
              ({ id, label, amount, repeatCallsAmount, repeatCallsShare }) => (
                <KeyTopicsListItem
                  key={id}
                  id={id}
                  label={label}
                  amount={amount}
                  repeatCallsAmount={repeatCallsAmount}
                  repeatCallsShare={repeatCallsShare}
                  isSelected={selectedKeyTopicId === id}
                  progressColorScheme="segments.quaternary"
                  onClick={() => {
                    setSelectedKeyTopicId(id)
                    analytics.event('repeat_calls_topic_selected')
                  }}
                />
              ),
            )}
          </>
        )}
      </List>
    )
  },
)
