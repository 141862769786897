import analytics from '@capturi/analytics'
import { Case, useDeleteCase } from '@capturi/api-cases'
import { useCurrentUser, useTeams } from '@capturi/core'
import { useOrganization } from '@capturi/stores'
import { Emoji } from '@capturi/ui-components'
import { useConfirm } from '@capturi/use-confirm'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Flex,
  HStack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  VStack,
  Wrap,
  WrapItem,
  chakra,
  useToast,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { ViewRaw } from 'components/ViewRaw'
import React, { useCallback, useMemo } from 'react'
import { MdCopyAll, MdDelete, MdMoreVert } from 'react-icons/md'
import { useCopyToClipboard } from 'react-use'
import CasesAvatarGroup from '../../../pages/Cases/components/CasesAvatarGroup'

type Props = {
  singleCase: Case
  userUids: string[]
  onCaseDeleted: () => void
}

const formatCustomFieldValue = (value: string | Date): string => {
  if (value instanceof Date) {
    return i18n.date(value, {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
  }
  return value
}

const Header: React.FC<Props> = ({ singleCase, userUids, onCaseDeleted }) => {
  const { conversationLanguage } = useOrganization()
  const compare = Intl.Collator(conversationLanguage).compare
  const [openRawCase] = useModal(ViewRaw)
  const { hasSuperPowers } = useCurrentUser()
  const toast = useToast()
  const [, copy] = useCopyToClipboard()
  const { getTeamByUid } = useTeams()
  const { mutate: deleteCase } = useDeleteCase()
  const confirm = useConfirm()

  const customPropTags = useMemo(
    () =>
      Object.entries(singleCase.customFields)
        .reduce<{ label: string; value: string }[]>((memo, [key, value]) => {
          const formattedValue = formatCustomFieldValue(value || '')
          memo.push({ label: key, value: formattedValue })
          return memo
        }, [])
        .sort((a, b) => compare(a.label, b.label)),
    [singleCase.customFields, compare],
  )

  const sortedLabels = useMemo(
    () => singleCase.tags.sort(compare),
    [singleCase.tags, compare],
  )

  const teamNames = useMemo(
    () =>
      singleCase.teams
        .map((teamUid) => getTeamByUid(teamUid))
        .map((team) => team?.name)
        .filter((name) => name != null && name.length > 0),
    [singleCase.teams, getTeamByUid],
  )

  const handleCopyCaseUid = useCallback((): void => {
    analytics.event('case_copyCaseUid')
    copy(singleCase.uid)
    toast({
      status: 'info',
      title: t`Copied ticket ID to clipboard`,
      description: singleCase.uid,
    })
  }, [copy, singleCase.uid, toast])

  const onCaseDelete = (): void => {
    deleteCase(singleCase.uid, {
      onSuccess: () => {
        toast({ title: t`Case successfully deleted`, status: 'success' })
        onCaseDeleted()
      },
      onError: (error) => {
        toast({
          title: t`Failed to delete the case. Please try again.`,
          status: 'error',
          description: error.message,
        })
      },
    })
  }

  const handleCaseDelete = async (): Promise<void> => {
    await confirm({
      title: t`Are you sure?`,
      description: (
        <>
          <Text mb="4">
            <Trans>
              This will delete this ticket and all data related to this ticket.
            </Trans>
          </Text>
        </>
      ),
      cancelText: t`Cancel`,
      confirmText: t`Delete`,
    })
    onCaseDelete()
  }

  return (
    <HStack
      justifyContent="space-between"
      align="flex-start"
      overflowX="hidden"
      minH="75px"
    >
      <VStack
        gap="0.5"
        align="flex-start"
        textOverflow="ellipsis"
        overflowX="hidden"
      >
        {singleCase.inbox && (
          <Tooltip label={singleCase.subject} hasArrow placement="top">
            <Heading
              display="inline-block"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              lineHeight="initial"
              maxW="100%"
              fontSize="2xl"
            >
              {singleCase.inbox}
            </Heading>
          </Tooltip>
        )}
        <Wrap spacing={1}>
          {singleCase.status && (
            <WrapItem>
              <Tag>
                <TagLabel>Status: {singleCase.status}</TagLabel>
              </Tag>
            </WrapItem>
          )}
          {customPropTags.map((t, i) => (
            <WrapItem key={i}>
              <Tag>
                {t.label}:
                <chakra.span ml={1} fontWeight="normal">
                  {t.value}
                </chakra.span>
              </Tag>
            </WrapItem>
          ))}
          {sortedLabels.map((label) => (
            <WrapItem key={label}>
              <Tag variant="solid" colorScheme="secondary">
                <TagLabel>{label}</TagLabel>
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      </VStack>
      <Box ml={4} flex="0 0 auto">
        <Flex direction="row" alignItems="center" gap={1}>
          <Flex gap={2}>
            <Box textAlign="right">
              <Flex align="center" justify="flex-end">
                <HStack gap="1">
                  <Text fontSize="sm">{singleCase.externalUid}</Text>
                </HStack>
              </Flex>
              <HStack gap="1" justify="flex-end">
                {teamNames.map((teamName) => (
                  <Tag
                    key={teamName}
                    variant="outline"
                    border="1px solid"
                    borderColor="gray.800"
                    color="gray.800"
                    boxShadow="none"
                    size="sm"
                  >
                    {teamName}
                  </Tag>
                ))}
                <Text color="textMuted">
                  {i18n.date(singleCase.created, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                </Text>
              </HStack>
            </Box>
            <CasesAvatarGroup userUids={userUids} size="sm" />
          </Flex>
          <Menu placement="bottom-end">
            <MenuButton
              color="gray.600"
              icon={<MdMoreVert />}
              as={IconButton}
              aria-label="Options"
              variant="ghost"
            />
            <MenuList>
              <MenuItem onClick={handleCopyCaseUid}>
                <MdCopyAll />
                <Text ml="2">
                  <Trans>Copy ticket ID to clipboard</Trans>
                </Text>
              </MenuItem>
              {hasSuperPowers && (
                <MenuItem
                  onClick={() =>
                    openRawCase({
                      externalUid: singleCase.externalUid,
                      integrationType: singleCase.source,
                    })
                  }
                >
                  <Emoji symbol="🥩" />
                  <Text ml="2">View Raw Case</Text>
                </MenuItem>
              )}
              <MenuItem onClick={handleCaseDelete}>
                <MdDelete />
                <Text ml="2">
                  <Trans>Delete</Trans>
                </Text>
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
    </HStack>
  )
}

export default Header
