import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'

const SubscriptionName: FC<{
  name: string
  setName: (name: string) => void
}> = ({ name, setName }) => {
  return (
    <FormControl>
      <FormLabel mb={1}>
        <Trans>Name</Trans>
      </FormLabel>
      <Input value={name} onChange={(e) => setName(e.target.value)} />
    </FormControl>
  )
}

export default SubscriptionName
