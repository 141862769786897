import {
  Button,
  Flex,
  HStack,
  InputGroup,
  Tag,
  TagCloseButton,
  TagLabel,
  Tooltip,
  VStack,
  chakra,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { FC, useRef, useState } from 'react'
import isEmailValid from 'utils/isEmailValid'

export const EmailChipsInput: FC<{
  recipients: string[]
  setRecipients: (recipients: string[]) => void
}> = ({ recipients, setRecipients }) => {
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setInputValue(event.target.value)
  }

  const handleSubmit = (): void => {
    const trimmedValue = inputValue.trim()
    if (trimmedValue) {
      addChips(trimmedValue)
      setRecipients([...recipients, ...[trimmedValue]])
      setInputValue('')
    } else {
    }
  }

  const handleInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit()
    } else if (
      event.key === 'Backspace' &&
      inputValue === '' &&
      recipients.length > 0
    ) {
      event.preventDefault()
      removeChip(recipients.length - 1)
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
    event.preventDefault()
    const pasteData = event.clipboardData.getData('Text')
    addChips(pasteData)
  }

  const addChips = (chips: string): void => {
    const newChips = chips
      .split(',')
      .map((chip) => chip.trim())
      .filter((chip) => !recipients.includes(chip))
    if (newChips.length > 0) {
      setRecipients([...recipients, ...newChips])
    }
  }

  const removeChip = (index: number): void => {
    setRecipients(recipients.toSpliced(index, 1))
  }

  const handleClearAll = (): void => {
    setInputValue('')
    setRecipients([])
    inputRef.current?.focus()
  }

  return (
    <InputGroup id="inputgroup" maxW="100%">
      <VStack flexGrow={1} maxW="100%" align="flex-start">
        <HStack
          flexWrap="wrap"
          maxH="4.6rem"
          overflowY="auto"
          py={1.5}
          px={2}
          gap={1.5}
          borderWidth={1}
          onClick={() => inputRef.current?.focus()}
          padding={2}
          minH="75px"
          border="solid 1px"
          borderColor="border.light"
          borderRadius="md"
          tabIndex={0}
          alignItems="start"
          boxShadow="inputOutline"
          w="100%"
          _focusWithin={{
            borderColor: 'primary.500',
            shadow: 'extended',
          }}
          sx={{
            '&::-webkit-scrollbar': {
              width: '0px',
            },
            '&': {
              scrollbarWidth: 'none',
            },
          }}
        >
          <Flex flexWrap="wrap" maxH="6.6rem" overflowY="scroll" flex="80%">
            {recipients.map((chip, index) => (
              <Tooltip
                key={index}
                label={t`Invalid email address`}
                hasArrow
                placement="top"
              >
                <Tag
                  size="xs"
                  borderRadius="full"
                  border={!isEmailValid(chip) ? '1px solid' : 'initial'}
                  borderColor={!isEmailValid(chip) ? 'danger' : 'initial'}
                  px={2}
                  mx={1}
                  mb={2}
                  variant="subtle"
                  maxW="100%"
                  h={5}
                >
                  <TagLabel
                    fontSize="xs"
                    fontWeight="normal"
                    color={!isEmailValid(chip) ? 'danger' : 'initial'}
                  >
                    {chip}
                  </TagLabel>
                  <TagCloseButton
                    ml={1}
                    h={4}
                    onClick={() => removeChip(index)}
                  />
                </Tag>
              </Tooltip>
            ))}
            <chakra.input
              ref={inputRef}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              onPaste={handlePaste}
              placeholder={t`Add email...`}
              fontSize="sm"
              border="none"
              outline="none"
              h={5}
              mx={1}
            />
          </Flex>
          <Flex
            pointerEvents="auto"
            w="auto"
            justify="flex-end"
            flex="20%"
            align="end"
          >
            {recipients.length > 0 && (
              <Button
                variant="ghost"
                _hover={{ backgroundColor: 'none' }}
                _focus={{ backgroundColor: 'none' }}
                aria-label={t`Clear all`}
                onClick={handleClearAll}
              >
                <Trans>Clear all </Trans>
              </Button>
            )}
          </Flex>
        </HStack>
      </VStack>
    </InputGroup>
  )
}
