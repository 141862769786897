import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'
import { DaysPeriodType } from '../../types'

const PeriodPreviousDays: FC<{
  period: DaysPeriodType
  setPeriod: (period: DaysPeriodType) => void
}> = ({ period, setPeriod }) => {
  return (
    <Stack direction="row" align="center" gap={0}>
      <Text fontSize="sm" color="gray.600">
        <Trans>Data from the last</Trans>
      </Text>
      <NumberInput
        maxW="70px"
        ml={2}
        display="inline"
        size="sm"
        min={1}
        max={90}
        onChange={(e) => {
          setPeriod({
            ...period,
            value: Number(e),
          })
        }}
        value={period.value ?? '7'}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <Text fontSize="sm" color="gray.600" ml={2}>
        <Trans>days</Trans>
      </Text>
    </Stack>
  )
}

export default PeriodPreviousDays
