import request, { ResponseError } from '@capturi/request'

import { PhoneFilterValues } from '@capturi/api-filters'
import { toFilterValues } from '@capturi/filters'
import {
  InfiniteData,
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query'
import { useEffect, useMemo, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'

import {
  BaseConversation,
  ConversationsInclScoresResponseModel,
  ConversationsInclTrackerHitsResponseModel,
  ConversationsResponseModel,
} from '../models'

export const getNextPageParam = (lastPage: {
  continuationToken: string | null
}) => lastPage.continuationToken

const PAGE_SIZE = 100

export const getNextSearchPageParam = () => undefined

export const selectConversations = (
  data: InfiniteData<ConversationsResponseModel>,
): InfiniteData<BaseConversation[]> => ({
  pages: data.pages.map((page) => page.conversations),
  pageParams: data.pageParams,
})

export const selectFirstNConversations = (
  data: InfiniteData<ConversationsResponseModel>,
): InfiniteData<BaseConversation[]> => {
  if (data.pages.length > 0 && data.pages[0].conversations.length > PAGE_SIZE) {
    return {
      pages: data.pages.map((page) => page.conversations.slice(0, PAGE_SIZE)),
      pageParams: data.pageParams,
    }
  }
  return {
    pages: data.pages.map((page) => page.conversations),
    pageParams: [],
  }
}

export const selectConversationsSearchCounts = (
  data: InfiniteData<ConversationsResponseModel>,
):
  | { count: number; numberOfConversationsSearched: number | null }
  | undefined => {
  if (data.pages.length === 0) return undefined
  return {
    count: data.pages[0].conversations.length,
    numberOfConversationsSearched: data.pages[0].numberOfConversationsSearched,
  }
}

const selectConversationsWithTrackerHits = (
  data: InfiniteData<ConversationsInclTrackerHitsResponseModel>,
): InfiniteData<ConversationsInclTrackerHitsResponseModel> => ({
  pages: data.pages.map((page) => page),
  pageParams: data.pageParams,
})

const queryKey = (
  filter: Record<string, unknown>,
  searchPhrases?: string[],
) => ['conversations', 'list', { filter }, { searchPhrases }]

const fetchConversations = async ({
  pageParam,
  filter,
  searchPhrases,
}: {
  pageParam: string | null
  filter: Record<string, unknown>
  searchPhrases?: string[]
}) => {
  const response = await request.post<ConversationsResponseModel>(
    'conversations/list?api-version=3.3&excludeDeleted=false',
    {
      json: pageParam
        ? { continuationToken: pageParam }
        : {
            ...filter,
            searchPhrases:
              searchPhrases !== undefined && searchPhrases.length > 0
                ? searchPhrases
                : undefined,
            limit: PAGE_SIZE,
            sortDirection: 1,
          },
    },
  )
  return response
}

export const useConversations = ({
  filter,
  enabled = true,
  searchPhrases,
}: {
  filter: Record<string, unknown>
  enabled?: boolean
  searchPhrases?: string[]
}): UseInfiniteQueryResult<InfiniteData<BaseConversation[], unknown>, Error> & {
  isInSearchMode: boolean
  canLoadMoreSearchResults: boolean
  loadMoreSearchResults: () => void
} => {
  const [showAllSearchResults, setShowAllSearchResults] = useState(false)
  const previousSearchPhrasesRef = useRef<string[] | undefined>()
  const previousFilterRef = useRef<Record<string, unknown> | undefined>()

  // Reset showAllSearchResults when filter, enabled, or searchPhrases change
  useEffect(() => {
    if (!isEqual(previousSearchPhrasesRef.current, searchPhrases)) {
      setShowAllSearchResults(false)
      previousSearchPhrasesRef.current = searchPhrases
    }
    if (!isEqual(previousFilterRef.current, filter)) {
      setShowAllSearchResults(false)
      previousFilterRef.current = filter
    }
  }, [filter, searchPhrases])

  const result = useInfiniteQuery({
    enabled: enabled,
    queryKey: queryKey(filter, searchPhrases),
    queryFn: async ({ pageParam }) =>
      fetchConversations({
        pageParam,
        filter,
        searchPhrases,
      }),
    getNextPageParam,
    initialPageParam: null,
    select: selectConversations,
  })

  return useMemo(() => {
    const isInSearchMode =
      searchPhrases !== undefined && searchPhrases.length > 0
    const canLoadMoreSearchResults =
      !showAllSearchResults &&
      result.data !== undefined &&
      result.data.pages.length > 0 &&
      result.data.pages[0].length > PAGE_SIZE
    const loadMoreSearchResults = () => {
      setShowAllSearchResults(true)
    }

    if (isInSearchMode && canLoadMoreSearchResults) {
      // separating it into if avoids TypeScript errors
      return {
        ...result,
        data: {
          ...result.data,
          pages: [
            result.data.pages[0].slice(0, PAGE_SIZE),
            ...result.data.pages.slice(1),
          ],
        },
        isInSearchMode,
        canLoadMoreSearchResults,
        loadMoreSearchResults,
      }
    }

    return {
      ...result,
      isInSearchMode,
      canLoadMoreSearchResults,
      loadMoreSearchResults,
    }
  }, [result, showAllSearchResults, searchPhrases])
}

export const useConversationsSearchCount = ({
  filter,
  searchPhrases,
  enabled,
}: {
  filter: Record<string, unknown>
  searchPhrases: string[]
  enabled: boolean
}): UseInfiniteQueryResult<
  { count: number; numberOfConversationsSearched: number | null } | undefined,
  Error
> => {
  return useInfiniteQuery({
    enabled: enabled,
    queryKey: queryKey(filter, searchPhrases),
    queryFn: async ({ pageParam }) =>
      fetchConversations({
        pageParam,
        filter,
        searchPhrases,
      }),
    getNextPageParam,
    initialPageParam: null,
    select: selectConversationsSearchCounts,
  })
}

export const useConversationsIncludingTrackerHits = (
  filter: Record<string, unknown>,
): UseInfiniteQueryResult<
  InfiniteData<ConversationsInclTrackerHitsResponseModel, unknown>,
  ResponseError
> => {
  return useInfiniteQuery({
    queryKey: ['conversationsWithTrackerHits', filter],
    queryFn: async ({ pageParam }) =>
      await request.post<ConversationsInclTrackerHitsResponseModel>(
        'conversations/including-tracker-hits?api-version=3.3&excludeDeleted=false',
        {
          json: pageParam
            ? { continuationToken: pageParam }
            : { ...filter, limit: 30, sortDirection: 1 },
        },
      ),
    getNextPageParam,
    initialPageParam: null,
    select: selectConversationsWithTrackerHits,
  })
}

export const useConversationsIncludingScores = (
  filter: Record<string, unknown>,
): UseInfiniteQueryResult<
  InfiniteData<ConversationsInclScoresResponseModel>,
  ResponseError
> => {
  return useInfiniteQuery({
    queryKey: ['conversationsWithScores', filter],
    queryFn: async ({ pageParam }) =>
      await request.post<ConversationsInclScoresResponseModel>(
        'conversations/including-scores?api-version=3.3&excludeDeleted=false',
        {
          json: pageParam
            ? { continuationToken: pageParam }
            : { ...filter, limit: 30, sortDirection: 1 },
        },
      ),
    getNextPageParam,
    initialPageParam: null,
  })
}

const selectConversationsCount = (data: { conversations: number }): number =>
  data.conversations
export function useConversationMatchCount(
  filterValues: PhoneFilterValues | undefined,
  period: { from: Date; to: Date },
): UseQueryResult<number, Error> {
  return useQuery({
    queryKey: ['conversationMatchCount', filterValues, period.from, period.to],
    queryFn: async () => {
      const response = await request.post<{ conversations: number }>(
        'conversations/count?api-version=3.3',
        {
          json: {
            ...toFilterValues(filterValues ?? {}),
            fromInclusive: period.from,
            toInclusive: period.to,
          },
        },
      )
      return response
    },
    select: selectConversationsCount,
  })
}

export const useCallHistory = (
  customer: string,
): UseQueryResult<ConversationsResponseModel, ResponseError> =>
  useQuery({
    queryKey: ['callHistory', customer],
    queryFn: async () =>
      request.post<ConversationsResponseModel>(
        'conversations/list?api-version=3.3&excludeDeleted=false',
        {
          json: {
            limit: 50,
            customers: [customer],
          },
        },
      ),
  })
