import { Button, ButtonGroup, Flex, Tooltip } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { FC } from 'react'
import { Days, WeeklyScheduleType } from '../../types'

const weekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const

const translateItems = (type: (typeof weekDays)[number]): string => {
  switch (type) {
    case 'Monday':
      return t({ id: 'StartLetterMonday', message: 'M' })
    case 'Tuesday':
      return t({ id: 'StartLetterTuesday', message: 'T' })
    case 'Wednesday':
      return t({ id: 'StartLetterWednesday', message: 'W' })
    case 'Thursday':
      return t({ id: 'StartLetterThursday', message: 'T' })
    case 'Friday':
      return t({ id: 'StartLetterFriday', message: 'F' })
    case 'Saturday':
      return t({ id: 'StartLetterSaturday', message: 'S' })
    case 'Sunday':
      return t({ id: 'StartLetterSunday', message: 'S' })
  }
}

const RecurrenceWeekly: FC<{
  schedule: WeeklyScheduleType
  setSchedule: (schedule: WeeklyScheduleType) => void
}> = ({ schedule, setSchedule }) => {
  const handleSelectDay = (day: Days): void => {
    const index = schedule.days.indexOf(day)
    if (index >= 0) {
      const newSelectedDays = schedule.days.toSpliced(index, 1)
      setSchedule({
        type: 'Weekly',
        days: newSelectedDays,
      })
    } else {
      setSchedule({
        type: 'Weekly',
        days: [...schedule.days, day],
      })
    }
  }
  return (
    <Flex flexDir="column" justify="flex-start">
      <ButtonGroup
        isAttached
        border="1px solid"
        borderRadius="6px"
        borderColor="gray.300"
        ml={2}
      >
        {weekDays.map((d) => {
          const isSelected = schedule.days.includes(d)
          const isDisabled = schedule.days.length === 1
          return (
            <Tooltip
              key={d}
              isDisabled={!(isDisabled && isSelected)}
              hasArrow
              placement="top"
              label={t`Minimum 1 day must be selected.`}
            >
              <Button
                value={d}
                _hover={{ backgroundColor: 'none' }}
                backgroundColor={isSelected ? 'primary.500' : 'white'}
                isDisabled={isDisabled && isSelected}
                color={isSelected ? 'white' : 'inherit'}
                borderRight="1px solid"
                borderColor="gray.300"
                _last={{ borderRight: 'none' }}
                onClick={() => handleSelectDay(d)}
              >
                {translateItems(d)}
              </Button>
            </Tooltip>
          )
        })}
      </ButtonGroup>
    </Flex>
  )
}

export default RecurrenceWeekly
