import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { Subscription, SubscriptionPayload } from '../types'

const cacheKey = 'subscriptions'

export const useCreateSubscription = (): UseMutationResult<
  Subscription,
  ResponseError,
  SubscriptionPayload,
  ResponseError
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (model: SubscriptionPayload) => {
      return request.post<Subscription>('subscriptions?api-version=3.3', {
        json: model,
      })
    },
    onSuccess: (newSubscription) => {
      queryClient.setQueryData<Subscription[]>([cacheKey], (oldData) => {
        if (!oldData) {
          return [newSubscription]
        }
        return [newSubscription, ...oldData]
      })
    },
  })
}
