import analytics from '@capturi/analytics'
import { MasterPreset } from '@capturi/api-shared'
import {
  CreateTrackerFolderPayload,
  TrackerFolder,
  trackerFoldersAPI,
  useCreateTrackerFolder,
  useUpdateTrackerFolder,
} from '@capturi/api-trackers'
import {
  PreviewAccessRequestModel,
  PreviewAccessResponse,
  useCurrentUser,
} from '@capturi/core'
import { getAcl, getPermissionPreset } from '@capturi/filters'
import {
  PersonsWithAccess,
  ShareAndEditOptions,
  SharingModalFormContent,
  usePreviewAccess,
} from '@capturi/sharing'
import { useToast } from '@capturi/ui-components'
import { OnChangeValue, SelectOption } from '@capturi/ui-select'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { MasterColumn } from 'pages/TrackersPage/components/MasterColumn'
import getMasterPreset from 'pages/TrackersPage/utils/getMasterPreset'
import useGetPresetOptions from 'pages/TrackersPage/utils/presetOptions'
import React, { useState } from 'react'
import isEqual from 'react-fast-compare'

function getPreviewAccessWarningText(
  previewAccessResponse: PreviewAccessResponse | undefined,
): string | undefined {
  switch (previewAccessResponse) {
    case 'NoAccess':
      return t`*You will no longer be able to view the folder when you apply`
    case 'Editable':
      return undefined
    case 'Viewable':
      return t`*You will no longer be able to edit the folder when you apply because you set your own permission to “viewer” `
  }
}

export type CreateUpdateTrackerFolderDialogProps = {
  folder?: TrackerFolder
  enableMasterTracker?: boolean
} & BaseModalComponentProps<{
  folder?: TrackerFolder
}>

type CreateTrackerFolderModel = CreateTrackerFolderPayload & {
  hasWarnings?: boolean
}

const TitleInput: React.FC<{
  inputRef?: React.RefObject<HTMLInputElement>
  value: string | undefined
  onChange: (value: string) => void
}> = ({ value, onChange, inputRef }) => {
  return (
    <FormControl isRequired>
      <FormLabel htmlFor="folder-title">
        <Trans>Name</Trans>
      </FormLabel>
      <Input
        id="folder-title"
        ref={inputRef}
        placeholder={t`Name`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        value={value}
      />
    </FormControl>
  )
}

export const CreateUpdateTrackerFolderDialog: React.FC<
  CreateUpdateTrackerFolderDialogProps
> = ({
  isOpen = false,
  onClose,
  onSubmit,
  folder: folderProp,
  enableMasterTracker,
}) => {
  const { mutate: createFolder } = useCreateTrackerFolder()
  const { mutate: updateFolder } = useUpdateTrackerFolder()
  const toast = useToast()
  const currentUser = useCurrentUser()
  // Refs
  const formRef = React.useRef<HTMLFormElement>(null)
  const titleRef = React.useRef<HTMLInputElement>(null)

  const presetOptions = useGetPresetOptions()

  // State
  const initialPermissionPreset = folderProp?.permissionPreset ?? 'Private'
  const initialMasterSettings = folderProp?.masterSettings ?? null
  const initialPermissionPresetUserUid =
    folderProp?.permissionPresetUserUid ?? initialPermissionPreset === 'Private'
      ? currentUser.id
      : null
  const [state, _setState] = React.useState<CreateTrackerFolderModel>(() => ({
    title: folderProp?.title ?? '',
    permissionPreset: initialPermissionPreset,
    permissionPresetUserUid: initialPermissionPresetUserUid,
    acl: folderProp?.acl ?? [],
    masterSettings:
      enableMasterTracker && currentUser.permissions.editMasterTracker
        ? {
            preset: folderProp?.masterSettings?.preset ?? 'Private',
          }
        : null,
  }))

  const [toggledMasterFolder, setToggledMasterFolder] = useState<boolean>(
    !!initialMasterSettings,
  )

  const setState = React.useCallback(
    (partialState: Partial<CreateTrackerFolderModel>): void => {
      _setState((state) => ({
        ...state,
        ...partialState,
        acl: getAcl(partialState, state, currentUser.id),
        permissionPresetUserUid: getPermissionPreset(
          partialState,
          state,
          currentUser.id,
        ),
      }))
    },
    [currentUser.id],
  )

  const selectedPreset = React.useMemo(() => {
    return presetOptions.find((x) => x.value === state.masterSettings?.preset)
  }, [presetOptions, state.masterSettings?.preset])

  const previewAccessRequestModel: PreviewAccessRequestModel = {
    uid: folderProp?.uid,
    permissionPreset: state.permissionPreset,
    permissionPresetUserUid: state.permissionPresetUserUid,
    acl: state.acl,
    folderUid: null,
  }
  const { isPreviewAccessLoading, previewAccessResponse, validationError } =
    usePreviewAccess({
      previewAccessRequestModel,
      createPreviewAccessRequest: trackerFoldersAPI.getPreviewAccess,
    })

  if (
    previewAccessResponse &&
    state.hasWarnings !==
      ['NoAccess', 'Viewable'].includes(previewAccessResponse)
  ) {
    setState({
      hasWarnings: ['NoAccess', 'Viewable'].includes(previewAccessResponse),
    })
  }

  const handleSelectPreset = (
    option: OnChangeValue<SelectOption, false>,
  ): void => {
    if (option === null) return
    const value = option.value
    setState({ masterSettings: { preset: value as MasterPreset } })
  }

  const localizedEntityName = t`tracker folder`
  const showsACL = state.permissionPreset === 'Custom'

  const handleSubmitChanges = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault()
    if (formRef.current?.checkValidity() === false) {
      return
    }
    if (folderProp) {
      const changedPreset = isEqual(
        folderProp.masterSettings?.preset,
        state.masterSettings?.preset,
      )
        ? undefined
        : { preset: state.masterSettings?.preset }
      const presetToPost = toggledMasterFolder ? changedPreset : null
      updateFolder(
        {
          uid: folderProp.uid,
          folder: { ...state, masterSettings: presetToPost },
        },
        {
          onSuccess: (folder) => {
            toast({ title: t`Updated tracker folder`, status: 'success' })
            analytics.event('tracker-analysis_TrackerFolderUpdated', {
              permissionPreset: state.permissionPreset,
            })
            onSubmit?.({ folder })
          },
          onError: (error) => {
            const errorMsg =
              error instanceof Error
                ? `${error.name}: ${error.message}`
                : undefined

            toast({
              title: t`Could not update tracker folder`,
              description: errorMsg,
              status: 'error',
            })
          },
        },
      )
    } else {
      if (!toggledMasterFolder) state.masterSettings = null
      createFolder(state, {
        onSuccess: (folder) => {
          toast({ title: t`Created new tracker folder`, status: 'success' })
          analytics.event('tracker-analysis_TrackerFolderCreated')
          onSubmit?.({ folder })
        },
        onError: (error) => {
          const errorMsg =
            error instanceof Error
              ? `${error.name}: ${error.message}`
              : undefined

          toast({
            title: t`Could not create new tracker folder`,
            description: errorMsg,
            status: 'error',
          })
        },
      })
    }
  }

  const modalHeader = folderProp
    ? t`Edit tracker folder`
    : t`Add new tracker folder`
  const submitButtonText = folderProp ? t`Save folder` : t`Create folder`

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={titleRef}
      size={state.permissionPreset === 'Custom' ? '4xl' : 'lg'}
    >
      <ModalOverlay>
        <ModalContent>
          <ModalCloseButton />
          <form ref={formRef} onSubmit={handleSubmitChanges}>
            <SharingModalFormContent
              modalHeader={modalHeader}
              submitButtonText={submitButtonText}
              isPreviewAccessLoading={isPreviewAccessLoading}
              previewAccessWarningText={getPreviewAccessWarningText(
                previewAccessResponse,
              )}
              validationError={validationError}
              onClose={onClose}
            >
              <Grid
                templateColumns={{
                  base: '1fr',
                  md: showsACL ? '1fr 1px 1fr' : '1fr',
                }}
                gap={4}
              >
                <Stack
                  borderRight={{
                    base: 'none',
                    md: showsACL
                      ? '1px solid var(--chakra-colors-gray-200)'
                      : 'none',
                  }}
                  pr={{
                    base: 0,
                    md: showsACL ? 8 : 0,
                  }}
                  pb={{ base: 2, md: 0 }}
                  spacing={4}
                >
                  <TitleInput
                    inputRef={titleRef}
                    value={state.title}
                    onChange={(value) => setState({ title: value })}
                  />

                  <ShareAndEditOptions
                    permissionPreset={state.permissionPreset}
                    onChange={(permissionPreset) =>
                      setState({ permissionPreset })
                    }
                    localizedEntityName={localizedEntityName}
                  />
                </Stack>
                <Divider />
                {showsACL && (
                  <Flex maxH={{ base: '660px', md: '430px' }}>
                    <PersonsWithAccess
                      acl={state.acl || []}
                      onChange={(acl) => setState({ acl: acl })}
                    />
                  </Flex>
                )}
                {enableMasterTracker &&
                  currentUser.permissions.editMasterTracker && (
                    <MasterColumn
                      presetOptions={presetOptions}
                      selectedPreset={selectedPreset}
                      renderPreset={getMasterPreset}
                      setToggledMaster={setToggledMasterFolder}
                      toggledMaster={toggledMasterFolder}
                      handleSelectPreset={handleSelectPreset}
                    />
                  )}
              </Grid>
            </SharingModalFormContent>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
